import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { iSquad, iUser } from '../model/all-classes';
import { YoumatterDbDataService } from '../service/youmatter-db-data.service';
import firebase from 'firebase';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.page.html',
  styleUrls: ['./search-users.page.scss'],
})
export class SearchUsersPage implements OnInit {

  public usersList: iUser[];
  public searchTerm: string = '';
  public selectedSquad: iSquad;

  public searchMessageUsers: boolean;

  squadLink: boolean = false;

  constructor(public router: Router,
    public alertController: AlertController,
    public service: YoumatterDbDataService,
    public navCtrl: NavController,) {
    if (!service.currentUser || !service.currentUser.uid) {
      navCtrl.navigateRoot('/login');
    }

    this.selectedSquad = new iSquad()
    if (service.selectedSquad.uid && service.selectedSquad.membersList) {
      this.selectedSquad = JSON.parse(JSON.stringify(this.service.selectedSquad)) ;
      this.squadLink = true;
    }

    if(service.onHomePage){
      this.squadLink = false;
    }   
    
  }

  ngOnInit() {
    this.searchMessageUsers = this.service.searchMessageUsers;
    var self = this;
    self.usersList = [];
    self.searchTerm = '';
    self.getUsersList();
    this.service.usersListCheck.subscribe((value) => {
      self.getUsersList();
    });
  }

  sendHealthBuddyRequest(user){
    this.service.sendHealthBuddyRequest(user);
  }

  getUsersList() {
    var self = this;
    self.usersList = [];

    if (self.service.allUsersList && self.service.allUsersList.length) {
      if (self.searchTerm) {
        for (var i = 0, j = self.service.allUsersList.length; i < j; i++) {
          if (self.service.allUsersList[i].fullName.toLowerCase().includes(self.searchTerm.toLowerCase()) ||
            self.service.allUsersList[i].email.toLowerCase().includes(self.searchTerm.toLowerCase())) {
            self.usersList.push(JSON.parse(JSON.stringify(self.service.allUsersList[i])));
          }
        }

      } else {
        self.usersList = JSON.parse(JSON.stringify(self.service.allUsersList));
      }
    }

    var uIndex = self.usersList.findIndex(obj => obj.uid == self.service.currentUser.uid);
    if (uIndex >= 0) {
      self.usersList.splice(uIndex, 1);
    }
  }

  addSquadUser(uid) {
    var self = this;
    if (!self.selectedSquad.membersList && !self.selectedSquad.membersList.length) {
      self.selectedSquad.membersList = [];
    }
    var uIndex = self.selectedSquad.membersList.indexOf(uid);
    if (uIndex >= 0) {
      self.selectedSquad.membersList.splice(uIndex, 1);
    } else {
      self.selectedSquad.membersList.push(uid);
    }

  }

  backToSquadPage() {
    this.service.selectedSquad = JSON.parse(JSON.stringify(this.selectedSquad)) ;
    this.service.updateSquadUserCheck.next() ;
    this.navCtrl.back();
  }

  profileSelected(){
    if(this.service.searchMessageUsers){
      this.router.navigateByUrl('/chat-detail');
    }    
  }

  showChat(){
    this.router.navigateByUrl('/chat-detail');       
  }

  showCoachProfile(data: any){
    //console.log(data)
  } 

  followUser(){

  }


}
