import { Component, OnInit } from '@angular/core';
import { YoumatterDbDataService } from 'src/app/service/youmatter-db-data.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(public service: YoumatterDbDataService) { }

  ngOnInit() {}

}
