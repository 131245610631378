import { Component, ViewChild } from '@angular/core';
import { Platform, NavController, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { YoumatterDbDataService } from './service/youmatter-db-data.service';
import firebase from 'firebase';
declare var FirebasePlugin: any;
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
//import { Badge } from '@ionic-native/badge/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private firebaseX: FirebaseX,
    private localNotifications: LocalNotifications,
    public service: YoumatterDbDataService,
    public navCntrl: NavController,
  ) {
    this.platform.backButton.subscribe(() => {
      if (!this.routerOutlet.canGoBack()) {
        navigator['app'].exitApp();
      }
    });
    this.initializeApp();

    // if (this.service.currentUser) {
    //   navCntrl.navigateRoot(['tabs/tab1']);
    // }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString("#FFFFFF");
      this.splashScreen.hide();
      this.notificationsMethods();
    });
  }


  notificationsMethods() {

    this.firebaseX.grantPermission();
    this.firebaseX.getToken().then(token => {
      this.setUserToken(token);
    }).catch(error => {
      console.log(error);
    })

    this.firebaseX.onTokenRefresh().subscribe(token => {
      this.setUserToken(token);
    })

    this.firebaseX.onMessageReceived().subscribe(data => {
      //console.log(`FCM message: `);
      if (!data.tap) {
        this.localNotifications.schedule({
          id: 1,
          title: data.title,
          text: data.body,
          foreground: true,
          smallIcon: '/src/assets/imgs/logo2.png',
        });
      }
      // if (data.tap === 'background' && data.pageUrl) {
      //   this.navCntrl.navigateForward([data.pageUrl]);
      // } 
    });

    // if (this.platform.is("cordova")) {

    //   FirebasePlugin.grantPermission(function (hasPermission) {
    //     console.log("Permission was " + (hasPermission ? "granted" : "denied"));
    //   });
    //   FirebasePlugin.hasPermission(function (hasPermission) {
    //     console.log("Permission is " + (hasPermission ? "granted" : "denied"));
    //   });
    //   FirebasePlugin.getToken((token) => {
    //     this.setUserToken(token);
    //   }, function (error) {
    //     console.error(error);
    //   });
    //   FirebasePlugin.onTokenRefresh((token) => {
    //     if (localStorage.getItem("deviceToken") !== token) {
    //         this.setUserToken(token);
    //     }
    //   }, function (error) {
    //     console.error(error);
    //   });


    //   FirebasePlugin.onMessageReceived((message) => {
    //     console.log(message);
    //     if (!message.tap) {
    //       this.localNotifications.schedule({
    //         id: 1,
    //         title: message.title,
    //         text: message.body,
    //         foreground: true,
    //         smallIcon: '/assets/imgs/logo2.png',
    //       });
    //     } 
       
    //   })

    // }
  }

  setUserToken(token) {
    localStorage.setItem("deviceToken", token);
    var uid = this.service.currentUser.uid;
    if (uid) {
      firebase.database().ref().child(`users/${uid}/deviceTokens`)
        .once('value').then(snapshot => {
          var tokens: Array<any> = snapshot.val() || [];
          if (tokens.indexOf(token) < 0) {
            tokens.push(token);
            firebase.database().ref().child(`users/${uid}/deviceTokens`).set(tokens);
          }
        }).catch(err => {
          console.log(err.message);
        })
    }

  }
}