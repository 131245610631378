import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddEventPage } from '../app/add-event/add-event.page'

import { IonicSelectableModule } from 'ionic-selectable';
import { Camera } from '@ionic-native/camera/ngx';
import { HttpClientModule } from '@angular/common/http';
import firebase from 'firebase';
import { ChartsModule } from 'ng2-charts';
// import { NgxGeoautocompleteModule } from 'ngx-geoautocomplete';


import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { PayPal, PayPalPayment, PayPalConfiguration } from '@ionic-native/paypal/ngx';
import { SearchUsersPage } from './search-users/search-users.page';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TermsAndConditionsComponent } from './shared-components/terms-and-conditions/terms-and-conditions.component';

var firebaseConfig = {
  apiKey: "AIzaSyDYKU4-O9MSZ-gaOCvukVW7D9nVkHu7l88",
  authDomain: "youmatter-app.firebaseapp.com",
  databaseURL: "https://youmatter-app-default-rtdb.firebaseio.com",
  projectId: "youmatter-app",
  storageBucket: "youmatter-app.appspot.com",
  messagingSenderId: "855253464389",
  appId: "1:855253464389:web:8c535176aae8c45550fa2c",
  measurementId: "G-BDWGT201Z8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

@NgModule({
  declarations: [AppComponent,
    TermsAndConditionsComponent
    //  AddEventPage removed due to ngbuild failing
    ],
  entryComponents: [ 
    // AddEventPage 
  ],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    HttpClientModule,
    ChartsModule,
    IonicSelectableModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxGeoautocompleteModule,
  ],
  providers: [
    StatusBar,
    FirebaseX,
    LocalNotifications,
    PayPal,
    Camera,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SearchUsersPage,
    InAppBrowser
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
