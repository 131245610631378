export class iUser {
    uid: string;
    active: boolean;
    fullName: string;
    profileUrl: string;
    email: string;
    phoneNumber: string;
    gender: string;
    weight: number;
    height: number;
    birthDay: string;
    country: string;
    address: string;
    showLocation: boolean;
    deviceTokens: string[] =[];
    pictures: string[];
    fitnessGallery: string[];
    videos: iVideo[];
    icons: iIcon[];
    notification: iUserNotification;
    privacy: iUserPrivacy;
    timeStamp: number;
    unreadMsgCount: number;
    goals: string;
    coachType: string ;
    coach:string ;
    following: iUser[] =[];
    healthBuddies: iHealthBuddy[] =[];
}

export class iHealthBuddy {
    status: string;
    buddyId: string;
}

export class iUserNotification {
    hotTips: boolean;
    buddieRequest: boolean;
    squadMessage: boolean;
    event: boolean;
    appAlert: boolean;
}

export class iUserPrivacy {
    coachesOnly: boolean;
    closedOnlyMe: boolean;
    buddiesSquad: boolean;
}

export class iProduct {
    productId: string;
    active: boolean = false;
    name: string;
    price: number;
    imageUrls: any[];
    description: string;
    features: iFeature[];
    quantity: string;
    timeStamp: number;
    uid: string;
    clothingCategory: string; //added to cater for clothing category
    colours: iColour[]; //added for colors on clothing
}

export class iColour {
    colourId: string;
    name: string;
}

export class iFeature {
    name: string;
    value: string;
}

export class iSquad {
    squadId: string;
    membersList: string[];
    name: string;
    instructions: string;
    image: string;
    uid: string;
    timeStamp: number;
    steps: boolean;
    frequency: boolean;
    maintenance: boolean;
    muscleBuilding: boolean;
    toning: boolean;
    weightLoss: boolean;
    crossFit: boolean;
    running: boolean;
    cycling: boolean;
    gym: boolean;
}

export class iVideo {
    fileUrl: string;
    description: string;
    title: string;
}

export class iIcon {
    imageUrl: any;
    name: string;
}

export class iDailyStat {
    bodyFat: number;
    weight: number;
    BMI: number;
    bodyWater: number;
    BMR: number;
    metabolicAge: number;
    steps: number;
    date: any;
    timeStamp: number;
    dayId: string;
    frequency: iFrequency;
}

export class iFrequency {
    crossFitValue: number;
    crossFitTarget: number;
    runningValue: number;
    runningTarget: number;
    cyclingValue: number;
    cyclingTarget: number;
    gymValue: number;
    gymTarget: number;
}

export class iChat {
    messages: iMessage[];
    users: string[];
    chatId: string;
    unreadMsgCount: number;
    deletion: any;
    timeStamp: number;
}

export class iMessage {
    imgMessage: string;
    textMessage: string;
    timeStamp: number;
    uid: string;
}

export class iEvent {
    uid: string;
    eventId: string;
    timeStamp: number;
    date: string;
    time: string;
    name: string;
    description: string;
    squadId: string;
}

export class iCartItem {
    productId: string;
    name: string;
    description: string;
    imageUrls: string[];
    coachId: string;
    totalBill: number;
    quantity: number;
    features: iFeature[];
    price: number;
    size: string; //added this in for youMatter gear
    colour: any; //added this in for youMatter gear
    youMatterOrder: boolean; //added this to differentiate
}

export class iOrder {
    orderId: string;
    orderNo: string;
    productItems: iCartItem[];
    deliveryCharges: number;
    discount: number;
    totalAmount: number;
    uid: string;
    userInfo: iOrderUser;
    shippingInfo: iAddress;
    paymentMethod: string;
    paymentInfo: any;
    status: string;
    timeStamp: number;
    // youMatterOrder: boolean; //added this to differentiate
}

export class iOrderUser {
    fullName: string;
    email: string;
    phoneNumber: string;
    profileUrl: string;
}

export class iAddress {
    country: string;
    city: string;
    zipCode: string;
    address: string;
    phoneNumber: string;
    lat: number;
    lng: number;
    timeStamp: number;
    fullName: string;
    addressId: string;
}

export class iNotifications{
    notificationId:string='';
    senderId:string='';
    receiverId:string[]=[];
    title:string='';
    message:string='';
    timeStamp:Number=0;
}

export class iRequest{
    requestId:string='';
    status:string='';
    amount:number=0;
    description:string='';
    timeStamp:Number=0;
}