import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'select-gender',
    loadChildren: () => import('./select-gender/select-gender.module').then( m => m.SelectGenderPageModule)
  },
  {
    path: 'select-height',
    loadChildren: () => import('./select-height/select-height.module').then( m => m.SelectHeightPageModule)
  },
  {
    path: 'select-weight',
    loadChildren: () => import('./select-weight/select-weight.module').then( m => m.SelectWeightPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'icons',
    loadChildren: () => import('./icons/icons.module').then( m => m.IconsPageModule)
  },
  {
    path: 'icons/:id',
    loadChildren: () => import('./icons/icons.module').then( m => m.IconsPageModule)
  },
  {
    path: 'pictures',
    loadChildren: () => import('./pictures/pictures.module').then( m => m.PicturesPageModule)
  },
  {
    path: 'pictures/:id',
    loadChildren: () => import('./pictures/pictures.module').then( m => m.PicturesPageModule)
  },
  {
    path: 'progress-gallery',
    loadChildren: () => import('./progress-gallery/progress-gallery.module').then( m => m.ProgressGalleryPageModule)
  },
  {
    path: 'progress-gallery/:id',
    loadChildren: () => import('./progress-gallery/progress-gallery.module').then( m => m.ProgressGalleryPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'videos/:id',
    loadChildren: () => import('./videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'upload-video',
    loadChildren: () => import('./upload-video/upload-video.module').then( m => m.UploadVideoPageModule)
  },
  {
    path: 'progress-tracker',
    loadChildren: () => import('./progress-tracker/progress-tracker.module').then( m => m.ProgressTrackerPageModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./statistics/statistics.module').then( m => m.StatisticsPageModule)
  },
  {
    path: 'step-tracker',
    loadChildren: () => import('./step-tracker/step-tracker.module').then( m => m.StepTrackerPageModule)
  },
  {
    path: 'add-stats',
    loadChildren: () => import('./add-stats/add-stats.module').then( m => m.AddStatsPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'chat-detail',
    loadChildren: () => import('./chat-detail/chat-detail.module').then( m => m.ChatDetailPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'squad-detail',
    loadChildren: () => import('./squad-detail/squad-detail.module').then( m => m.SquadDetailPageModule)
  },
  {
    path: 'all-products',
    loadChildren: () => import('./all-products/all-products.module').then( m => m.AllProductsPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'all-coaches',
    loadChildren: () => import('./all-coaches/all-coaches.module').then( m => m.AllCoachesPageModule)
  },
  {
    path: 'coach-detail',
    loadChildren: () => import('./coach-detail/coach-detail.module').then( m => m.CoachDetailPageModule)
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'add-card',
    loadChildren: () => import('./add-card/add-card.module').then( m => m.AddCardPageModule)
  },
  {
    path: 'upload-video',
    loadChildren: () => import('./upload-video/upload-video.module').then( m => m.UploadVideoPageModule)
  },
  {
    path: 'add-hero-icon',
    loadChildren: () => import('./add-hero-icon/add-hero-icon.module').then( m => m.AddHeroIconPageModule)
  },
  {
    path: 'search-users',
    loadChildren: () => import('./search-users/search-users.module').then( m => m.SearchUsersPageModule)
  },
  {
    path: 'create-squad',
    loadChildren: () => import('./create-squad/create-squad.module').then( m => m.CreateSquadPageModule)
  },
  {
    path: 'top-performer',
    loadChildren: () => import('./top-performer/top-performer.module').then( m => m.TopPerformerPageModule)
  },
  {
    path: 'add-event',
    loadChildren: () => import('./add-event/add-event.module').then( m => m.AddEventPageModule)
  },
  {
    path: 'add-product',
    loadChildren: () => import('./add-product/add-product.module').then( m => m.AddProductPageModule)
  },
  {
    path: 'cart-items',
    loadChildren: () => import('./cart-items/cart-items.module').then( m => m.CartItemsPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'addresses',
    loadChildren: () => import('./addresses/addresses.module').then( m => m.AddressesPageModule)
  },
  {
    path: 'add-address',
    loadChildren: () => import('./add-address/add-address.module').then( m => m.AddAddressPageModule)
  },
  {
    path: 'checkout-order',
    loadChildren: () => import('./checkout-order/checkout-order.module').then( m => m.CheckoutOrderPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'coach-request',
    loadChildren: () => import('./components/coach-request/coach-request.module').then( m => m.CoachRequestPageModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('./components/requests/requests.module').then( m => m.RequestsPageModule)
  },
  {
    path: 'clothing-products',
    loadChildren: () => import('./clothing-products/clothing-products.module').then( m => m.ClothingProductsPageModule)
  },
  {
    path: 'paypal',
    loadChildren: () => import('./paypal/paypal.module').then( m => m.PaypalPageModule)
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },

  // {
  //   path: 'tab4',
  //   loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
