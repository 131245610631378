import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController, ToastController, AlertController, NavController } from '@ionic/angular';
//import { Plugins, CameraResultType, Capacitor, FilesystemDirectory, CameraPhoto, CameraSource } from '@capacitor/core';
import { iAddress, iCartItem, iDailyStat, iEvent, iHealthBuddy, iNotifications, iOrder, iProduct, iRequest, iSquad, iUser } from '../model/all-classes';
import firebase from 'firebase';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class YoumatterDbDataService {

  public isEditingSquad: boolean = false;

  public searchMessageUsers: boolean = true;
  public onHomePage: boolean;

  public userDataCheck: Subject<string> = new Subject<string>();
  public usersListCheck: Subject<string> = new Subject<string>();
  public statDataCheck: Subject<string> = new Subject<string>();
  public eventDataCheck: Subject<string> = new Subject<string>();
  public chatDataCheck: Subject<string> = new Subject<string>();
  public squadDataCheck: Subject<string> = new Subject<string>();
  public updateSquadUserCheck: Subject<string> = new Subject<string>();
  public productDataCheck: Subject<string> = new Subject<string>();
  public addressDataCheck: Subject<string> = new Subject<string>();
  public ordersDataCheck: Subject<string> = new Subject<string>();
  public clothingCategoryDataCheck: Subject<string> = new Subject<string>();

  public categoryClothingDataCheck: Subject<string> = new Subject<string>();

  public baseUploadURL: any = "https://codexia.xyz/youmatter/upload.php";

  public allUsersList: iUser[] = [];
  public allSquadsList: iSquad[] = [];
  public allStatsList: iDailyStat[] = [];
  public allSquadEvents: any = {};
  public allEventsList: iEvent[] = [];
  public allProductsList: iProduct[] = [];
  public allUserStats: any;
  public chatUser: iUser = new iUser();
  public currentUser: iUser = new iUser();
  public selectedSquad: iSquad = new iSquad();
  public selectedProduct: iProduct = new iProduct();
  public selectedCoach: iUser = new iUser();
  public selectedUser: iUser = new iUser();
  public selectedEvent: iEvent = new iEvent();
  public selectedAddress: iAddress = new iAddress();
  public orderDetail: iOrder = new iOrder();
  public squadDetail: string = '';
  public chatsList: any[] = [];
  public chatUsers: iUser[] = [];
  public loader: any;
  public statSelectedDate: any;
  public allCartItemsList: iCartItem[] = [];
  public myOrdersList: iOrder[] = [];
  public userOrdersList: iOrder[] = [];
  public addressList: iAddress[] = [];
  public allNotifications: iNotifications[] = [];
  public testimonials:any[] = [];
  public requests:iRequest[] = [];
  public clothingCategories: any[] =[];

  public myWallet:number = 0;

  public clothingCategorySelected: any = {};
  public clothingProducts: iProduct[] = [];

  public termsAndConditions: string = "";

  constructor(public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    public zone: NgZone,
    public alertController: AlertController,
    public navCtrl: NavController,
    public http: HttpClient,
    private router: Router
    ) {

    this.currentUser = new iUser();

    if (localStorage.getItem('youMatterUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('youMatterUser'));
      this.fetchAppData();
      this.getCurrentUserData();
    }

  }

  fetchAppData() {
    this.getTestimonialsData();
    this.getAddressList();
    this.getAllNotifications();
    this.getUsersDataList();
    this.getSquadsDataList();
    this.getEventsListData();
    this.getChatData();
    this.getUserStatsListData();
    this.getProductsList();
    this.getUserOrdersData();
    this.allCartItemsList = [];
    if (localStorage.getItem('youMatterCartItems')) {
      this.allCartItemsList = JSON.parse(localStorage.getItem('youMatterCartItems'));
    }
    if(this.currentUser && this.currentUser.coach=='Approved') {
      this.getWithdrawRequestsList();
    }
  }

  setCurrentUserData(userData) {
    //console.log(this.currentUser);
    this.currentUser = new iUser();
    this.currentUser = userData;

    if(!this.currentUser.hasOwnProperty('healthBuddies')){
      this.currentUser.healthBuddies = [];
    }
    if(!this.currentUser.hasOwnProperty('following')){
      this.currentUser.following = [];
    }

    localStorage.setItem('youMatterUser', JSON.stringify(userData));
  }


  getTestimonialsData() {
    var self = this;
    self.testimonials = [];
    firebase.database().ref().child('testimonials')
      .once('value', function (snapshot) {
        var data = snapshot.val();
        self.testimonials = [];
        if (data && data.length) {
          self.testimonials = data;
        }
      });
  }

  getAddressList() {
    var self = this;
    self.addressList = [];
    firebase.database().ref().child('addresses/' + self.currentUser.uid)
      .once('value', (snapshot) => {
        self.addressList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            self.addressList.push(data[key])
          }
          self.addressList.reverse();
        }
        self.addressDataCheck.next();
      });
  }


  getWithdrawRequestsList() {
    var self = this;
    self.requests = [];
    firebase.database().ref().child('withdrawRequests/' + self.currentUser.uid)
      .once('value', (snapshot) => {
        self.requests = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            self.requests.unshift(data[key])
          }
        }
      });
  }

  getCurrentUserData() {
    //console.log(this.currentUser);
    var self = this;

    firebase.database().ref().child('users')
      .orderByChild('uid').equalTo(self.currentUser.uid)
      .on('value', (snapshot) => {
        var data = snapshot.val();
        for (var key in data) {
          self.currentUser = data[key];

          if(!self.currentUser.hasOwnProperty('following')){
            self.currentUser.following = [];
          }
          if(!self.currentUser.hasOwnProperty('healthBuddies')){
            self.currentUser.healthBuddies = [];
          }

          localStorage.setItem('youMatterUser', JSON.stringify(self.currentUser));
          self.userDataCheck.next();
          break;
        }
      });

  }

  getUsersDataList() {
    var self = this;
    self.allUsersList = [];
    firebase.database().ref().child('users')
      .once('value', (snapshot) => {
        self.allUsersList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            if (!data[key].unreadMsgCount) {
              data[key].unreadMsgCount = 0;
            }
            self.allUsersList.push(data[key])
          }
        }
        self.getChatData();
        self.usersListCheck.next();
      });
  }

  getUserOrdersData() {
    var self = this;
    self.myOrdersList = [];
    self.userOrdersList = [];
    self.myWallet = 0;

    firebase.database().ref().child('orders')
      .once('value', (snapshot) => {
        self.myOrdersList = [];
        self.userOrdersList = [];
        self.myWallet = 0;
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            if (data[key].uid == self.currentUser.uid) {
              self.myOrdersList.unshift(data[key]);
            } else if (self.currentUser.coach == 'Approved') {
              var products: iCartItem[] = [];
              var orderData: iOrder = JSON.parse(JSON.stringify(data[key]));
              for (var i = 0, j = orderData.productItems.length; i < j; i++) {
                if (orderData.productItems[i].coachId == self.currentUser.uid) {
                  products.push(orderData.productItems[i]);
                 
                }
              }
              if (products && products.length) {
                orderData.productItems = products;
                self.userOrdersList.unshift(JSON.parse(JSON.stringify(orderData)));
              }
            }

          }
        }
        self.ordersDataCheck.next();
      });

  }

  getAllNotifications() {
    var self = this
    this.allNotifications = [];
    var uid = self.currentUser.uid;
    firebase.database().ref('notifications')
      .once('value', (snapshot) => {
        this.allNotifications = [];
        var data = snapshot.val();
        if (data) {
          this.zone.run(() => {
            for (var key in data) {
              var temp: iNotifications = data[key];
              if (temp.receiverId.includes(uid)) {
                this.allNotifications.unshift(temp);
              }
            }
          })

          this.allNotifications = this.allNotifications.slice(0, 7);
        }
      }).catch(error => {
        //console.log(error);
      })

    firebase.database().ref('notifications')
      .orderByChild('timeStamp').startAt(Date.now())
      .on("child_added", function (snapshot, previousChildKey) {
        var data = snapshot.val();
        var key = snapshot.key;
        if (self.allNotifications.length && self.allNotifications[0].notificationId == data.notificationId) {
          return;
        } else if (data.receiverId.includes(uid)) {
            self.allNotifications.unshift(data);
        }

      });

  }

  getSquadsDataList() {
    var self = this;
    self.allSquadsList = [];
    firebase.database().ref().child('squads')
      .once('value', (snapshot) => {
        self.allSquadsList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            if (data[key].membersList.includes(self.currentUser.uid)) {
              self.allSquadsList.push(data[key]);
            }
          }
        }
        self.squadDataCheck.next();
      });
  }

  getChatData() {
    var self = this;
    if (!self.allUsersList || !self.allUsersList.length) {
      return;
    }
    firebase.database().ref().child(`/chats`)
      .on('value', (snapshot) => {
        var data: any = snapshot.val();
        self.chatsList = [];
        if (data) {
          console.log(data);

          for (var key in data) {
            if (key.includes(self.currentUser.uid)) {

              var messages: any = []
              var delTime = -1;
              if (data[key].deletion && data[key].deletion[self.currentUser.uid]) {
                delTime = data[key].deletion[self.currentUser.uid];
                data[key].timeStamp = data[key].deletion[self.currentUser.uid];
              }
              for (var msg in data[key].messages) {
                if (data[key].messages[msg].timeStamp > delTime) {
                  messages.push(JSON.parse(JSON.stringify(data[key].messages[msg])))
                }
              }

              if (messages.length) {
                messages.reverse();
                data[key].timeStamp = messages[0].timeStamp;
                data[key].messages = JSON.parse(JSON.stringify(messages))
                self.chatsList.push(JSON.parse(JSON.stringify(data[key])))
              }

            }
          }

          if (self.chatsList && self.chatsList.length) {
            self.chatsList.sort((a, b) => (a.timeStamp < b.timeStamp) ? 1 : -1)
          }
        }
        self.getChatUsers();
        self.chatDataCheck.next();
      })
  }



  getChatUsers() {
    var self = this;
    self.chatUsers = [];
    for (var i = 0, j = self.chatsList.length; i < j; i++) {
      var uid = '';
      if (self.chatsList[i].users[0] == self.currentUser.uid) {
        uid = self.chatsList[i].users[1];
      } else {
        uid = self.chatsList[i].users[0];
      }
      var uData = self.allUsersList.find(obj => obj.uid == uid);
      if (uData && uData.fullName) {
        self.chatUsers[i] = JSON.parse(JSON.stringify(uData));
      } else {
        self.chatUsers[i] = new iUser();
      }
    }
  }

  getUserStatsListData() {
    var self = this;
    self.allStatsList = [];
    self.allUserStats = {};

    firebase.database().ref().child('statistics/')
      .once('value', (snapshot) => {
        self.allUserStats = {};
        self.allStatsList = [];
        if (snapshot.val()) {
          self.allUserStats = snapshot.val();

          if (self.allUserStats[self.currentUser.uid]) {
            var data = self.allUserStats[self.currentUser.uid];
            for (var key in data) {
              self.allStatsList.push(data[key])
            }
            self.allStatsList.sort((a, b) => (Number(new Date(a.date)) > Number(new Date(b.date)) ? 1 : -1));
          }
        }
        self.statDataCheck.next();
      });

  }

  getEventsListData() {
    var self = this;
    self.allSquadEvents = {};
    self.allEventsList = [];

    firebase.database().ref().child('events/')
      .once('value', (snapshot) => {
        self.allSquadEvents = {};
        self.allEventsList = [];
        if (snapshot.val()) {
          self.allSquadEvents = snapshot.val();
          for (var squads in self.allSquadEvents) {

            for (var events in self.allSquadEvents[squads]) {
              self.allEventsList.push(self.allSquadEvents[squads][events]);
            }
          }
        }
        self.eventDataCheck.next();
      });

  }

  getProductsList() {
    var self = this;
    self.allProductsList = [];
    firebase.database().ref().child('products')
      .once('value', (snapshot) => {
        self.allProductsList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            if(!data[key].clothingCategory)
              self.allProductsList.push(data[key])              
          }
          self.allProductsList.reverse();
        }
        self.productDataCheck.next();
      });
  }

  uploadVideoFile(formData: FormData) {
    return this.http.post(this.baseUploadURL, formData).toPromise();
  }

  async presentLoading(message?) {
    if (!this.loader) {
      this.loader = await this.loadingCtrl.create({
        message: message || ""
      });
      await this.loader.present();
      const { role, data } = await this.loader.onDidDismiss();
    }
  }

  stopLoading() {
    if (this.loader) {
      this.loader.dismiss();
      this.loader = null;
    }
  }

  async createToast(mess) {
    const toast = await this.toastCtrl.create({
      message: mess,
      duration: 3000
    });
    toast.present();
  }


  getUsersTokens(notifyData) {
    var self = this;

    self.saveNotificationFirebase(JSON.parse(JSON.stringify(notifyData)));

    firebase.database().ref().child('users')
      .once('value', (snapshot) => {
        var data = snapshot.val();

        if (data) {
          for (var i = 0, j = notifyData.user.length; i < j; i++) {
            var userData: iUser = JSON.parse(JSON.stringify(data[notifyData.user[i]]));
            var userDeviceTokens = [];
            if (userData && userData.deviceTokens && userData.deviceTokens.length) {
              for (var k = 0; k < userData.deviceTokens.length; k++) {
                if (userData.deviceTokens[k] && userData.deviceTokens[k].toLowerCase() != 'null') {
                  userDeviceTokens.push(userData.deviceTokens[k])
                }
              }
            } if (userDeviceTokens && userDeviceTokens.length) {
              //console.log(notifyData);
             // console.log(userDeviceTokens);
              this.sendUserPushNotification(notifyData, userDeviceTokens)
            }
          }
        }
      });
  }

  sendUserPushNotification(notifyData, userDeviceTokens) {

    var addMessage = firebase.functions().httpsCallable('sendNotification');
    addMessage({
      title: notifyData.title,
      message: notifyData.message,
      deviceToken: userDeviceTokens,
    }).then((result) => {

    }, (error) => {

      //console.log(error.message);
    });

    //console.log(addMessage);
  }

  saveNotificationFirebase(notifyData) {
    var self = this;
    var postData: iNotifications = {
      title: notifyData.title,
      message: notifyData.message,
      receiverId: notifyData.user,
      senderId: self.currentUser.uid,
      notificationId: firebase.database().ref().child('notifications/').push().key,
      timeStamp: Number(new Date()),
    };

    var updates: any = {};
    updates['/notifications/' + postData.notificationId] = postData;
    firebase.database().ref().update(updates).then(() => {

    }).catch((error) => {
 
      //console.log(error.message);
    });

  }


  public logoutUser() {
    var self = this;
    var user = firebase.auth().currentUser;
    if (this.currentUser && this.currentUser.uid) {
      var ref2: any = firebase.database().ref('notifications');
      ref2.off('child_added');
      var ref3: any = firebase.database().ref('users/' + this.currentUser.uid);
      ref3.off('value');
      var ref5: any = firebase.database().ref('chats/');
      ref5.off('value');
    }

    if (user) {
      self.removeCurrentTokenFromUser(user);
      var token = localStorage.getItem('deviceToken');
      localStorage.setItem('deviceToken', token);
      firebase.auth().signOut().then(() => {
        localStorage.setItem('deviceToken', token);
      }).catch((e) => {
        self.createToast(e.message);
      })
    }
    this.currentUser = new iUser();
    localStorage.removeItem('youMatterUser');
    localStorage.removeItem('youMatterCartItems');
    this.resetUser();
    this.navCtrl.navigateRoot('/login');
  }

  removeCurrentTokenFromUser(user) {
    var uid = user.uid;
    var token = localStorage.getItem("deviceToken");
    firebase.database().ref().child(`users/${uid}/deviceTokens`)
      .once("value").then(snapshot => {
        var tokens: Array<any> = snapshot.val() || [];
        var index = tokens.indexOf(token);
        if (index >= 0) {
          tokens.splice(index, 1);
          if (tokens.length > 0) {
            firebase.database().ref().child(`users/${uid}/deviceTokens`).set(tokens);
          } else {
            firebase.database().ref().child(`users/${uid}/deviceTokens`).set(null);
          }
        }
      }).catch(err => {
        //console.log(err.message);
      })
  }

  public resetUser() {
    this.allUsersList = [];
    this.allSquadsList = [];
    this.allStatsList = [];
    this.allSquadEvents = {};
    this.allEventsList = [];
    this.allProductsList = [];
    this.allUserStats = {};
    this.chatUser = new iUser();
    this.selectedSquad = new iSquad();
    this.selectedProduct = new iProduct();
    this.selectedCoach = new iUser();
    this.selectedUser = new iUser();
    this.selectedEvent = new iEvent();
    this.selectedAddress = new iAddress();
    this.orderDetail = new iOrder();
    this.squadDetail = '';
    this.chatsList = [];
    this.chatUsers = [];
    this.loader = null;
    this.statSelectedDate = null;
    this.allCartItemsList = [];
    this.myOrdersList = [];
    this.userOrdersList = [];
    this.addressList = [];
  }

  getCurrentBalance() {
    var totalIncome = 0;
    var withdraw = 0;
    if(this.userOrdersList) {
      for(var i=0, j=this.userOrdersList.length;i<j ; i++) {
        if(this.userOrdersList[i].status=='Completed') {
          var data = this.userOrdersList[i];
          for(var k=0, l=data.productItems.length;k<l ; k++) {
            totalIncome = totalIncome+data.productItems[k].totalBill;
          }
        }
      }

      if(this.requests) {
        for(var i=0, j=this.requests.length;i<j ; i++) {
          if(this.requests[i].status !='Cancelled') {
            withdraw = withdraw + this.requests[i].amount;
          } 
        }
      }
      
    }
    this.myWallet = totalIncome - withdraw ;
    return this.myWallet; 
  }


  uploadFileOnServer(formData: FormData): Promise<any> {
    return this.http.post(this.baseUploadURL, formData).toPromise();
  }

  isHealthBuddies(user): boolean{
    if(this.currentUser.hasOwnProperty('healthBuddies')){
      return this.currentUser.healthBuddies.find(obj => obj.buddyId == user.uid) == undefined ?  false :  true;
    }
    else{
      return false;
    }
  }

  sendHealthBuddyRequest(user){
    if(this.currentUser.healthBuddies.find(obj => obj.buddyId == user.uid) == undefined){
      let buddyRequest = {
        buddyId: user.uid,
        status: 'Pending'
      }
      
      //do friend request
      this.presentLoading();
      this.currentUser.healthBuddies.push(buddyRequest);
      var updates = {};
  
      updates['/users/' + this.currentUser.uid] = this.currentUser;
  
      firebase.database().ref().update(updates).then(() => {
        this.stopLoading();
        this.createToast('Health buddy request sent to ' + user.fullName);
        this.sendHealthBuddyPushNotification(user);
      }).catch((e) => {
        this.stopLoading();
        this.createToast(e.message);
      });
    }
    else{
      //unfriend user
      let index = this.currentUser.healthBuddies.findIndex(x => x.buddyId == user.uid)
      this.currentUser.healthBuddies.splice(index, 1);
      this.presentLoading();  
      var updates = {};

      updates['/users/' + this.currentUser.uid] = this.currentUser;
      firebase.database().ref().update(updates).then(() => {
        this.stopLoading();
        this.createToast(user.fullName + ' is no longer a health buddy');
      }).catch((e) => {
        this.stopLoading();
        this.createToast(e.message);
      });
    }

  }

  sendHealthBuddyPushNotification(user){
    var notifyData: any = {
      user: [user.uid],
      title: 'New Health Buddy Request',
      pageUrl: '/chat-detail',  //todo: change this pageurl
      message: 'You have received a new health buddy request from ' + this.currentUser.fullName,
    }
    this.getUsersTokens(notifyData)
  }

  getUserNameById(id): string{
    return this.getUserById(id).fullName;
  }

  getUserById(id){
    let user: any = {};
    firebase.database().ref().child('users/' + id)
      .once('value', (snapshot) => {
         user = snapshot.val();
      });

    return user;
  }



  acceptRejectHealthBuddyRequest(accepted: boolean, senderId: string, notifId: string){ 
    let sender = this.getUserById(senderId);
    var updates = {};
    var currentUserUpdates ={};
    

    if(accepted){
      let index = sender.healthBuddies.findIndex(obj => obj.buddyId == this.currentUser.uid);
      sender.healthBuddies[index].status = "Approved";
      let newBuddy: iHealthBuddy = {
        buddyId: senderId,
        status: "Approved"
      };

      this.currentUser.healthBuddies.push(newBuddy);

      updates['/users/' + senderId] = sender;
      currentUserUpdates['/users/' + this.currentUser.uid] = this.currentUser;

      firebase.database().ref().update(updates).then(() => {
        //accepted!
      }).catch((e) => {
        //console.log(e.message);
      });

      firebase.database().ref().update(currentUserUpdates).then(() => {
        //accepted!
      }).catch((e) => {
       // console.log(e.message);
      });


      this.createToast('Health buddy request accepted');
    } 
    else{
        var self = this;
        var updates = {};
        let index = sender.healthBuddies.findIndex(obj => obj.buddyId == this.currentUser.uid);
        sender.healthBuddies.splice(index, 1);
        
        updates['/users/' + senderId] = sender;
        
        firebase.database().ref().update(updates).then(() => {
          this.createToast('Health buddy request rejected');
        }).catch((e) => {
          //console.log(e.message);
        });     


    }


    this.deleteNotification(notifId);

  }

  deleteNotification(notifId){
    var updates = {};

    updates['/notifications/' + notifId] = null;

    firebase.database().ref().update(updates).then(() => {
      this.getAllNotifications();
      
    }).catch((e) => {
      //console.log(e.message);
    });  
  }
  
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
 }
  

 getClothingCategories() {
  var self = this;
  self.allProductsList = [];
  firebase.database().ref().child('clothingcategories')
    .once('value', (snapshot) => {
      self.clothingCategories = [];
      var data = snapshot.val();
      if (data) {
        for (var key in data) {
            self.clothingCategories.push(data[key])
        }
        self.clothingCategories.reverse();
      }
      self.clothingCategoryDataCheck.next();
    });
}


getTermsAndConditions() {
  firebase.database().ref().child('T&C/-MnzrYvlIz9dOQcz20q3')
      .once('value', (snapshot) => {
        var data = snapshot.val();
        this.termsAndConditions = data.description;
      });
}

getPAIAManual() {
  
}


}



///add something to db:
// let testBrand: any = {};
// testBrand.description = 'Vest';
// testBrand.categoryId = firebase.database().ref().child('clothingcategories/' + testBrand.categoryId + '/')
// .push().key;

// var updates = {};
// updates['/clothingcategories/' + testBrand.categoryId] = testBrand;
// firebase.database().ref().update(updates).then(() => {
//   console.log('added')
// }).catch((e) => {
//   console.log(e);
// });